var endpointMap = {
  ar: {
    strings: "translation-ar.json",
  },
  "ar-AE": {
    strings: "translation-ar.json",
  },
  bn: {
    strings: "translation-bn.json",
  },
  "bn-IN": {
    strings: "translation-bn.json",
  },
  cs: {
    strings: "translation-cs.json",
  },
  "cs-CZ": {
    strings: "translation-cs.json",
  },
  da: {
    strings: "translation-da.json",
  },
  "da-DK": {
    strings: "translation-da.json",
  },
  de: {
    strings: "translation-de.json",
  },
  "de-DE": {
    strings: "translation-de.json",
  },
  en: {
    strings: "translation-en.json",
  },
  "en-AE": {
    strings: "translation-en.json",
  },
  "en-AU": {
    strings: "translation-en.json",
  },
  "en-CA": {
    strings: "translation-en.json",
  },
  "en-GB": {
    strings: "translation-en.json",
  },
  "en-IE": {
    strings: "translation-en.json",
  },
  "en-IN": {
    strings: "translation-en.json",
  },
  "en-NG": {
    strings: "translation-en.json",
  },
  "en-SG": {
    strings: "translation-en.json",
  },
  "en-US": {
    strings: "translation-en.json",
  },
  "en-ZA": {
    strings: "translation-en.json",
  },
  es: {
    strings: "translation-es.json",
  },
  "es-ES": {
    strings: "translation-es.json",
  },
  "es-MX": {
    strings: "translation-es.json",
  },
  "es-US": {
    strings: "translation-es.json",
  },
  fr: {
    strings: "translation-fr.json",
  },
  "fr-BE": {
    strings: "translation-fr.json",
  },
  "fr-CA": {
    strings: "translation-fr.json",
  },
  "fr-FR": {
    strings: "translation-fr.json",
  },
  he: {
    strings: "translation-he.json",
  },
  "he-IL": {
    strings: "translation-he.json",
  },
  hi: {
    strings: "translation-hi.json",
  },
  "hi-IN": {
    strings: "translation-hi.json",
  },
  it: {
    strings: "translation-it.json",
  },
  "it-IT": {
    strings: "translation-it.json",
  },
  ja: {
    strings: "translation-ja.json",
  },
  "ja-JP": {
    strings: "translation-ja.json",
  },
  kn: {
    strings: "translation-kn.json",
  },
  "kn-IN": {
    strings: "translation-kn.json",
  },
  ko: {
    strings: "translation-ko.json",
  },
  "ko-KR": {
    strings: "translation-ko.json",
  },
  ml: {
    strings: "translation-ml.json",
  },
  "ml-IN": {
    strings: "translation-ml.json",
  },
  mr: {
    strings: "translation-mr.json",
  },
  "mr-IN": {
    strings: "translation-mr.json",
  },
  nl: {
    strings: "translation-nl.json",
  },
  "nl-BE": {
    strings: "translation-nl.json",
  },
  "nl-NL": {
    strings: "translation-nl.json",
  },
  pl: {
    strings: "translation-pl.json",
  },
  "pl-PL": {
    strings: "translation-pl.json",
  },
  pt: {
    strings: "translation-pt.json",
  },
  "pt-BR": {
    strings: "translation-pt.json",
  },
  "pt-PT": {
    strings: "translation-pt.json",
  },
  sv: {
    strings: "translation-sv.json",
  },
  "sv-SE": {
    strings: "translation-sv.json",
  },
  ta: {
    strings: "translation-ta.json",
  },
  "ta-IN": {
    strings: "translation-ta.json",
  },
  te: {
    strings: "translation-te.json",
  },
  "te-IN": {
    strings: "translation-te.json",
  },
  tr: {
    strings: "translation-tr.json",
  },
  "tr-TR": {
    strings: "translation-tr.json",
  },
  und: {
    strings: "translation.json",
  },
  zh: {
    strings: "translation-zh.json",
  },
  "zh-CN": {
    strings: "translation-zh.json",
  },
  "zh-TW": {
    strings: "translation-zh.json",
  },
};
var arbManifest = {
  endpointMap: endpointMap,
};

export { arbManifest as default, endpointMap };
