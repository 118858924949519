// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";
import PT from "prop-types";
import { ContentType } from "../../datamodel/Model";
import { getFormattedText, getText } from "../../../../utils/pantherHelper";

function getSystemMessage(bundle, textName, name) {
  if (name.includes("@")) {
    // candidate display name contains @
    return getText(bundle, textName);
  } else {
    return getFormattedText(bundle, textName + "-agent", {
      name: name,
    });
  }
}
export class SystemMessage extends React.PureComponent {
  static propTypes = {
    messageDetails: PT.object.isRequired,
  };

  static defaultProps = {};

  getMessageText = () => {
    console.log("SystemMessage getMessageText");
    console.log(this.props);
    const name = this.props.messageDetails.displayName;
    switch (this.props.messageDetails.content.type) {
      case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_JOINED:
        return getSystemMessage(this.props.bundle, "join-chat-message", name);
      case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_LEFT:
        return getSystemMessage(this.props.bundle, "left-chat-message", name);
      case ContentType.EVENT_CONTENT_TYPE.CHAT_ENDED:
        return getText(this.props.bundle, "end-chat-message");

      default:
        return "";
    }
  };

  render() {
    return <>{this.getMessageText()}</>;
  }
}
