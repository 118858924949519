// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

export const CONTACT_STATUS = {
  CONNECTED: "connected",
  MISSED: "missed",
  ACW: "acw",
  INCOMING: "incoming",
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  ENDED: "ended",
  ACCEPTED: "accepted",
  ERROR: "error",
};

export const KEYBOARD_KEY_CONSTANTS = {
  ENTER: "Enter",
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  TAB: "Tab",
  ESC: "Escape",
  SPACE: " ",
  DIGIT_0: "0",
  DIGIT_1: "1",
  DIGIT_2: "2",
  DIGIT_3: "3",
  DIGIT_4: "4",
  DIGIT_5: "5",
  DIGIT_6: "6",
  DIGIT_7: "7",
  DIGIT_8: "8",
  DIGIT_9: "9",
  BACKSPACE: "Backspace",
  DELETE: "Delete",
};
