import { COUNTRY_CONFIGURATION } from "../config";

export async function redirectTrafficToConnectByPercentage(
  language,
  country,
  jobType,
  urlPrefix,
  connect
) {
  // only US/CA/MX chat & sms channel enabled for dial-up strategy
  if (
    COUNTRY_CONFIGURATION[country][language][jobType].enableConnectDialUp ===
    "true"
  ) {
    let configurations = await fetch(
      `${urlPrefix}/settings.json`
    ).then((result) => result.json());

    const stage = configurations["stage"];
    const twilioChatUrl = configurations["twilioChatUrl"];
    const dialUpPercentage = Number(
      configurations["dialUpStrategy"][country][language][jobType][stage]
    );
    const previousCallCenter = localStorage.getItem("CallCenter");
    const randomPercentage = Math.random() * 100;

    if (connect) {
      return;
    }

    // Check dial-up percentage
    if (dialUpPercentage === 0) {
      await routeToTwilio(twilioChatUrl);
    } else if (dialUpPercentage === 100) {
      localStorage.setItem("CallCenter", "Connect");
      return;
    }

    // Check previous call center
    if (previousCallCenter === "Twilio") {
      await routeToTwilio(twilioChatUrl);
    } else if (previousCallCenter === "Connect") {
      localStorage.setItem("CallCenter", "Connect");
      return;
    }

    // Check random percentage
    if (randomPercentage > dialUpPercentage) {
      await routeToTwilio(twilioChatUrl);
    } else {
      localStorage.setItem("CallCenter", "Connect");
    }
  }
}

async function routeToTwilio(twilioChatUrl) {
  localStorage.setItem("CallCenter", "Twilio");
  window.location.href = twilioChatUrl + window.location.search;
  await new Promise((f) => setTimeout(f, 1000));
}
