import strings from "../text/strings.puff.json";
export const pantherResources = JSON.parse(JSON.stringify(strings));
export function getText(bundle, text) {
  if (!bundle) {
    if (pantherResources.resources[text].value) {
      return pantherResources.resources[text].value;
    } else {
      return pantherResources.resources[text];
    }
  } else {
    return bundle.getMessage(text);
  }
}
export function getFormattedText(bundle, text, args) {
  if (!bundle) {
    if (pantherResources.resources[text].value) {
      return pantherResources.resources[text].value;
    } else {
      return pantherResources.resources[text];
    }
  } else {
    return bundle.formatMessage(text, args);
  }
}
