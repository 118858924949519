import React from "react";
import { ThemeProvider } from "./theme";
import styled from "styled-components";

import ChatContainer from "./components/Chat/ChatContainer";

import defaultTheme from "./theme/defaultTheme";

const Page = styled.div`
  font-family: ${(props) => props.theme.fonts.regular};
  border-collapse: collapse;
  box-shadow: 0px 2px 3px ${(props) => props.theme.palette.alto};

  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  min-height: 300px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    position: fixed;
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin: auto;
    height: 80vh;
    width: 60%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    margin: auto;
    height: 80vh;
    width: 50%;
  }

  @media screen and (min-width: 1441px) {
    margin: auto;
    height: 80vh;
    width: 35%;
  }
`;

const AppProvider = (props) => {
  return (
    <ThemeProvider theme={Object.assign({}, defaultTheme, props.themeConfig)}>
      {props.children}
    </ThemeProvider>
  );
};

App.defaultProps = {
  baseCssClass: "connect-customer-interface",
};

function App({ baseCssClass, ...props }) {
  return (
    <AppProvider themeConfig={props.themeConfig || {}}>
      <Page className={baseCssClass}>
        <ChatContainer {...props} />
      </Page>
    </AppProvider>
  );
}

export default App;
