import React, { useCallback, useEffect, useState } from "react";
import { useBundle } from "@amzn/react-arb-tools";
import { getText } from "./utils/pantherHelper";
import { Col } from "@amzn/stencil-react-components/layout";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { Label } from "@amzn/stencil-react-components/text";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Card } from "@amzn/stencil-react-components/card";
import { H1 } from "@amzn/stencil-react-components/text";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { SpinnerSize } from "@amzn/stencil-react-components/dist/submodules/spinner/interfaces";
import { redirectTrafficToConnectByPercentage } from "./utils/dialUpHelper";

export const InputCard = ({
  initChatApp,
  inputEditorDir,
  language,
  country,
  jobType,
  urlPrefix,
  connect,
}) => {
  const [bundle, isBundleLoading] = useBundle("strings");
  const [emailAddress, setEmailAddress] = useState("");
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(true);
  const [loading, setLoading] = useState(true);

  const loadingComponent = (
    <Col height={"100vh"} alignItems={"center"} justifyContent={"center"}>
      <Spinner size={SpinnerSize.Medium} showText={false} />
    </Col>
  );

  const requestDialUpConfig = useCallback(async () => {
    setLoading(true);
    try {
      await redirectTrafficToConnectByPercentage(
        language,
        country,
        jobType,
        urlPrefix,
        connect
      );
    } catch (e) {}
    setLoading(false);
  }, [language, country, jobType, urlPrefix, connect]);

  useEffect(() => {
    requestDialUpConfig().then((r) => {});
  }, [requestDialUpConfig]);

  if (isBundleLoading) {
    return loadingComponent;
  }

  if (loading) {
    return loadingComponent;
  }

  const submitEmailAddress = () => {
    if (!emailAddress || !validateEmail(emailAddress)) {
      setIsEmailAddressValid(false);
    } else {
      initChatApp(emailAddress);
    }
  };

  const validateEmail = (emailAddress) => {
    return String(emailAddress)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Col alignItems={"center"} padding={"7rem 1rem 0 1rem"} gridGap={"2rem"}>
      <H1>{getText(bundle, "input-card-header")}</H1>
      <Card
        justifyContent="center"
        alignItems="center"
        maxWidth={800}
        width={"100%"}
        backgroundColor={"#f2f4f7"}
      >
        <Col
          gridGap={"3rem"}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
        >
          <Col width={"100%"} gridGap={"1rem"}>
            <Label>{getText(bundle, "input-card-email-address")}</Label>
            <Input
              dir={inputEditorDir}
              id="email-address-input"
              type="text"
              placeholder="example@email.com"
              onChange={(e) => {
                setIsEmailAddressValid(true);
                setEmailAddress(e.target.value);
              }}
              error={!isEmailAddressValid}
            />
            <Col>
              <InputFooter
                id="email-address-error"
                error
                style={{ display: isEmailAddressValid ? "none" : "" }}
              >
                {getText(bundle, "input-card-error-message")}
              </InputFooter>
            </Col>
          </Col>
          <Col width={250}>
            <Button
              variant={ButtonVariant.Primary}
              onClick={submitEmailAddress}
            >
              {getText(bundle, "input-card-chat-now-button")}
            </Button>
          </Col>
        </Col>
      </Card>
    </Col>
  );
};
